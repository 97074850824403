@font-face {
  font-family: "NeueHaasGrotesk";
  src: url("/src/fonts/39496.otf") format("opentype");
}

.container {
  width: 100vw;
  height: 100vh;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: #de5126;
}

.logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: spin infinite 10s linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Logo */

.logoContainer {
  display: flex;
  margin-bottom: 10rem;
}

.logotype {
  margin-right: 1rem;
}

/* Content */

.content {
  padding: 8rem 5rem;
}

/* Description */

.description {
  font-family: "NeueHaasGrotesk", Helvetica, sans-serif;
  color: #fff;
  font-size: 50px;
  width: 694px;
  margin-bottom: 2rem;
}

/* Input */
.inputContainer {
  display: flex;
}

.input {
  width: 370px;
  height: 50px;
  font-size: 18px;
  line-height: 28px;
  font-family: "NeueHaasGrotesk", Helvetica, sans-serif;
  padding: 0 1rem;
  border: 2px solid transparent;
  outline: none;
  background-color: #f3f3f4;
  color: #0d0c22;
  transition: 0.3s ease;
}

.input::placeholder {
  color: #888888;
}

.input:focus,
input:hover {
  outline: none;
  border-color: rgba(222, 81, 38, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(222 81 38 / 10%);
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  border-radius: 0;
  border: none;
  background-color: #de5126;
  color: white;
  font-family: "NeueHaasGrotesk", Helvetica, sans-serif;
  font-size: 18px;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: spin 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.error {
  margin-top: 1rem;
  color: rgb(200, 0, 0);
  font-family: "NeueHaasGrotesk", Helvetica, sans-serif;
}